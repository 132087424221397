<template>
  <div>
    <div class="form--group row mb-4">
      <label class="col-12 col-lg-3" for="search-place-field">
        <span>Lokasi</span>
        <span class="required">*</span>
      </label>
      <div class="col-12 col-lg-6">
        <search-places class="input--margin" />
        <span class="val-error" v-if="validation.hasError('isDefaultLatLng')">{{
          validation.firstError('isDefaultLatLng')
        }}</span>
      </div>
    </div>
    <div style="position: relative">
      <section-loading :show="loadingMap" />
      <div class="map--container" id="propertyMap"></div>
    </div>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';

const SearchPlaces = () =>
  import('@/components/project-management/project/form/map/search-places.vue');
import SectionLoading from '@/components/content-loading/section-loading';
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';
export default {
  name: 'map-section',
  props: ['isActive'],
  components: {
    SearchPlaces,
    SectionLoading,
  },
  mixins: [LodashMixin],
  data() {
    return {
      icon: {
        url: '/img/map_pin.svg', // url
        // eslint-disable-next-line no-undef
        scaledSize: new google.maps.Size(40, 40), // scaled size
        // eslint-disable-next-line no-undef
        origin: new google.maps.Point(0, 0), // origin
        // eslint-disable-next-line no-undef
        anchor: new google.maps.Point(20, 40), // anchor
      },
      map: null,
      marker: null,
      detailZoom: 15,
      overallZoom: 10,
      mapCreated: false,
      isDefaultLatLng: true,
    };
  },
  beforeCreate() {
    this.lat = -6.1753924;
    this.lng = 106.8249641;
    this.isDefaultLatLng = true;
  },
  created() {},
  async mounted() {
    console.log('mounted map');
    this.$nextTick(() => {
      this.initMap();
    });
  },
  computed: {
    ...mapState({
      province: (state) => state.project.form.regional.province,
      city: (state) => state.project.form.regional.city,
      loadingMap: (state) => state.project.form.loadingMap,
    }),
    lat: {
      get() {
        return this.$store.state.project.form.lat;
      },
      set(val) {
        this.$store.commit('project/form/SET_LAT', val);
      },
    },
    lng: {
      get() {
        return this.$store.state.project.form.lng;
      },
      set(val) {
        this.$store.commit('project/form/SET_LNG', val);
      },
    },
  },
  watch: {
    // fetchingEditListingData(value){
    //     if(!value){
    //         if(this.form==='edit'){
    //             this.initMap();
    //         }
    //     }
    // },
    lat(val, oldVal) {
      this.checkIsDefault();
      if (oldVal !== '' && oldVal != null) {
        this.changeLocationByLatLong(this.lat, this.lng);
      }
    },
    lng(val, oldVal) {
      this.checkIsDefault();
      if (oldVal !== '' && oldVal != null) {
        this.changeLocationByLatLong(this.lat, this.lng);
      }
    },
  },
  validators: {
    isDefaultLatLng(value) {
      console.log('IS DEFAULT LAT LNG: ', value);
      if (this.isActive) {
        return Validator.value(value).custom(() => {
          if (value) {
            return this.$i18n.t('errors.addProject.location.required');
          }
        });
      }
      return Validator.value(true);
    },
  },
  methods: {
    validate() {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
    checkIsDefault() {
      this.isDefaultLatLng = this.lat === -6.1753924 && this.lng === 106.8249641;
    },
    setLoading(value) {
      this.$store.commit('v2/listingForm/SET_LOADING', value);
    },
    placeDetailFound() {
      this.map.setZoom(this.detailZoom);
    },
    changeLocationByLatLong(lat, lng) {
      if (this.mapCreated) {
        // eslint-disable-next-line no-undef
        const latlng = new google.maps.LatLng(lat, lng);
        this.marker.setPosition(latlng);
        this.map.panTo(latlng);
      }
    },
    getFitBound() {
      // eslint-disable-next-line no-undef
      const bound = new google.maps.LatLngBounds();
      bound.extend(this.marker.position);
      return bound;
    },
    initMarker() {
      // eslint-disable-next-line no-undef
      this.marker = new google.maps.Marker({
        position: {
          lat: this.lat,
          lng: this.lng,
        },
        icon: this.icon,
        map: this.map,
        draggable: true,
        // eslint-disable-next-line no-undef
        animation: google.maps.Animation.DROP,
      });
      this.marker.addListener('dragend', (event) => {
        const self = this;
        this.lat = event.latLng.lat();
        this.lng = event.latLng.lng();
        // eslint-disable-next-line no-undef
        google.maps.event.addListenerOnce(this.map, 'bounds_changed', function () {
          if (this.getZoom() > self.detailZoom) {
            this.setZoom(self.detailZoom);
          }
        });
        this.map.fitBounds(this.getFitBound());
      });
    },
    async initMap() {
      let zoom = 5;
      zoom = this.detailZoom;
      const options = {
        center: { lat: this.lat, lng: this.lng },
        zoom: zoom,
        zoomControl: true,
        zoomControlOptions: {
          // eslint-disable-next-line no-undef
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        streetViewControl: false,
        maxZoom: 18,
      };
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById('propertyMap'), options);
      this.mapCreated = true;
      this.initMarker();
    },
  },
};
</script>

<style scoped></style>
